import { proxy } from "valtio";
import * as THREE from "three";


const stateInArea = proxy ({
    inArea: false,
    inAreaIndex: "", 
    inAreaPastIndex:"",
    eisenZeitClck: false,
})

const stateInAreaRooms = proxy ({
    inAreaRoom: false,
    inAreaRoomEntryRoom: true,
})

const stateInAreaSchaustelle = proxy ({
    inAreaSchaustelle: false,
   
})

const stateOrbitLocation = proxy({
    orbitClicked: false,
    CameraPos: new THREE.Vector3(0,0,0),
    TargetPos: new THREE.Vector3 (0,0,0),
    shouldUpdate: true,
    lerpRuns: false


})

const statePlayerPositionS = proxy ({
playerPositionS: new THREE.Vector3(0, 0, -150),
playerRotationS: new THREE.Euler(0, 0, 0),
// playerRotationS: new THREE.Euler(0, 0, 0),
// playerPositionS: new THREE.Vector3 (-150, 0, 0),
// playerRotationS: new THREE.Euler(0, 1.65, 0),

playeractive: true,
playerCameraTargetPosition: new THREE.Vector3(0,0,0),
fpPlayer: false,
playerInput: true,
homeclicked: false,
travelclicked:false,
blockPlayerTravel: true,
homeFinished: true,
teleportScreen: false,
isTracking: false


})

const stateUiDisplay = proxy({
    storyTextMatch: false,
    orbitData: false,
    openInfoWindow: false,
   
})

const stateDevice = proxy({
    isMobile: false,
    isTablet: false,
    isIphone: false,
    isHandy:false,
    isSafari: false
})

const stateTouchCircle = proxy(
    {
        x: 0,
        y: 0,
       joystickMove: false
    }
) 

const stateTouchCircleLook = proxy(
    {
        x: 0,
        y: 0,
        OrbitOn:false,
        // JoystickLookOn:false,
        JoystickLookVisible: true
       
    }
) 

const stateWallPush = proxy(
    {
        activeThemeIndex: -1
    }
)


const statedynamicLight = proxy(
    {
        videoLight: false
    }
)

const stateLoadingScreen = proxy(

    {
        loadingScreenAnimate: true,
       lastInfoWindowClick: false,
        baseLoaded:false,
        basePercent: 0,
        showLoaderRooms: false,
        startPressed: false
       
    }
)

const stateIframeWindow = proxy(

    {
        showIframe: false,
        showIframeScetch: false,
        src: "",
        renderCanvas: true,
        showVideoPlane: false,
        VideoSrc:"",
        AnnotationId:"",
        LowModelOrbit: false
    }
)

const statePlayerSwitch = proxy ({
   playerWoman:false,
   charSwitchClicked: false,
   characterUiOn: false
    
    
    })

  const stateMDR = proxy ({
    museaumActive: ""
     
     
     })

     const stateSound = proxy ({
        backAudio: true
         
         
         })

         const stateImageGallery = proxy ({
            galleryOn: false,
            galleryIndex: ""
             
             
             })

             const stateMemory = proxy (
                {
                    memoryLowDevice: false,
                    performanceLowDevice: false,
                    scetchfabOff: false,
                    lowModelCamZoom: 10,
                    deviceToOld:false
                }
             )
    
             const stateEnglisch = proxy ({
                isEnglisch: false
                 
                 
                 })

                 const stateMDRText = proxy ({
                    isRegional: false,
                    isPrimaer:false
                     
                     
                     })

export {
    stateInArea,
    statePlayerPositionS,
    stateOrbitLocation,
    stateUiDisplay,
    stateDevice,
    stateTouchCircle,
    stateWallPush,
statedynamicLight,
stateLoadingScreen,
stateIframeWindow,
stateTouchCircleLook,
statePlayerSwitch,
stateMDR,
stateInAreaRooms,
stateSound,
stateImageGallery,
stateInAreaSchaustelle,
stateMemory,
stateEnglisch,
stateMDRText


}