import "./ThemMonitorTwo.css";
import { Plane, Text } from "@react-three/drei";
import { Clone, Html } from "@react-three/drei";
import { useEffect, useState, useRef } from "react";
import * as THREE from "three";
import GlbLoader from "../Utils/GlbLoader";
import stateThemeMonitor from "./states/stateThemeMonitor";
import { stateInArea, stateInAreaRooms, stateDevice } from "../store";
import CheckInArea from "../Utils/CheckInArea.jsx";
import { useSnapshot } from "valtio";
import { useMemo } from "react";
import FontLoader from "../Utils/FontLoader";
import { easing } from "maath";
import { useFrame } from "@react-three/fiber";

const mPointsLength = stateThemeMonitor.length;

export default function ThemeMonitor() {
  const snapInAreaRooms = useSnapshot(stateInAreaRooms);
  const [clones, setClones] = useState([]);

  const clonesData = useMemo(() => {
    // Calculate the rotation for each clone based on its index (x)
    return [...Array(mPointsLength).keys()].map((monitor, x) => {
      const {
        x: rotationX,
        y: rotationY,
        z: rotationZ,
      } = stateThemeMonitor[x].Rotation;

      const rotationInRadians = [
        THREE.MathUtils.degToRad(rotationX),
        THREE.MathUtils.degToRad(rotationZ),
        THREE.MathUtils.degToRad(rotationY),
      ];

      const adjustCheckPosition = stateThemeMonitor[x].Position.clone();
      adjustCheckPosition.add(
        new THREE.Vector3(0, 0, 40).applyEuler(
          new THREE.Euler(
            rotationInRadians[0],
            rotationInRadians[1],
            rotationInRadians[2]
          )
        )
      );

      return {
        key: x,
        position: [...stateThemeMonitor[x].Position],
        rotation: rotationInRadians,
        index: [...stateThemeMonitor[x].Id],
        link: stateThemeMonitor[x].Link,
        adjustCheckPosition: adjustCheckPosition,
      };
    });
  }, [mPointsLength, stateThemeMonitor]);

  useEffect(() => {
    setClones(clonesData);
  }, []);

  return (
    <>
<group visible={snapInAreaRooms.inAreaRoomEntryRoom}>

        {clones.map((clone) => (
          <ThemeMonitorKon
            key={clone.key}
            scale={1}
            position={clone.position}
            rotation={clone.rotation}
            link={clone.link}
            adjustCheckPosition={clone.adjustCheckPosition}
            index={stateThemeMonitor[clone.key].Id}
          ></ThemeMonitorKon>
        ))}
      </group>
    </>
  );
}

function ThemeMonitorKon({
  position,
  rotation,
  index,
  adjustCheckPosition,
  link,
}) {
  const modelRef = useRef();
  const { themeMonitor } = GlbLoader();
  const [iframeActive, setIframeActive] = useState(false);
  const snapPlayerInsideArea = useSnapshot(stateInArea);
  // const playerInsideArea = snapPlayerInsideArea.inArea;
  const [areaActive, setAreaActive] = useState(false);
  const screenPositionStatic = new THREE.Vector3(0, 25.8, 0);
  const [hovered, setHovered] = useState(false);
  const snapStateDevice = useSnapshot(stateDevice);
  const htmlRef = useRef();
  const { titelFont } = FontLoader();

  titelFont.fontSize = 2;

  const rotationInRadians = [
    THREE.MathUtils.degToRad(0),
    THREE.MathUtils.degToRad(0),
    THREE.MathUtils.degToRad(0),
  ];

  const {
    themeHandelsWegeMap,
    themeHolzarbeitMap,
    themeKlimaMap,
    themeMdrMap,
    themeMenschenMap,
    themeSalzabbauMap,
  } = GlbLoader();

  const textures = {
    ThemeM_Salzabbau: themeSalzabbauMap,
    ThemeM_MuseenDerRegion: themeMdrMap,
    ThemeM_Menschen: themeMenschenMap,
    ThemeM_Klima: themeKlimaMap,
    ThemeM_Holzarbeit: themeHolzarbeitMap,
    ThemeM_Handelswege: themeHandelsWegeMap,
  };

  const handlePlaneClick = () => {
    if (areaActive) {
      setIframeActive(true);
    }
  };

  useEffect(() => {
    // Call the callback function with the updated playerInsideArea value
    if (!snapPlayerInsideArea.inArea) {
      setIframeActive(false);
    }
  }, [snapPlayerInsideArea.inArea]);

  useEffect(() => {
    if(areaActive){
    document.body.style.cursor = hovered ? "pointer" : "auto";
  }
  }, [hovered]);

  const handleMouseOver = () => {
    setHovered(true);
  };

  const handleMouseOut = () => {
    setHovered(false);
  };

  useFrame((state, delta) => {
    // htmlRef.current.rotation = 0
    if (snapStateDevice.isSafari && iframeActive) {
      easing.dampE(
        htmlRef.current.rotation,
        [0, -0.01 * (Math.PI / 10), 0],
        0,
        delta
      );
    }
  });

  return(
    <>
      <Clone
        scale={1}
        position={position}
        rotation={rotation}
        object={themeMonitor.scene}
        ref={modelRef}
      >
        {iframeActive ? (
          <group
            ref={htmlRef}
            position={screenPositionStatic}
            rotation={rotationInRadians}
          ><Html
              transform
              wrapperClass="htmlScreenTwo"
              distanceFactor={2.45}
              occlude="blending"
              zIndexRange={[2,0]}
              scale={[9,9,9]}
            >
              <iframe
                src={link}
                style={{userSelect:"none"}}
              />
            </Html>
          </group>
        ) : (
          <Plane
            args={[47,26]}
            position={screenPositionStatic}
            rotation={rotationInRadians}
            onClick={handlePlaneClick}
            onPointerEnter={handleMouseOver}
            onPointerLeave={handleMouseOut}
          >
            {areaActive ? (
              <Text
                position={[-3,-8.6,1]}
                rotation={[0,0,0]}
                color={"white"}
                {...titelFont}
              >CLICK</Text>
            ) : null}
            <meshStandardMaterial
              map={textures[index]}
              roughness={0}
              metalness={0}
              needsUpdate={true}
              toneMapped={false}
            />
          </Plane>
        )}
      </Clone>
      <CheckInArea
        squareA={73}
        squareB={73}
        position={adjustCheckPosition}
        rotation={rotation}
        index={index}
        setAreaActive={setAreaActive}
      />
    </>
  );
}