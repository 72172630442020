import IframeWindow from "./MainUI/IFrameWindow.jsx";
import JoystickLook from "./MainUI/JoystickLook.jsx";
import SoundBackground from "./Utils/SoundBackground";
import JoystickMovement from "./MainUI/Joystick.jsx";
import { AnimatePresence } from "framer-motion";
import InfoWindowStory from "./MainUI/InfoWindowStory.jsx";
import Header from "./MainUI/Header";
import LoadingBar from "./MainUI/LoadingBar";
import ToolBar from "./MainUI/ToolBar";
import CustomLoaderProgress from "./MainUI/CustomLoaderProgress";
import CharacterSwitchUi from "./MainUI/characterSwitchUi.jsx";
import { useSnapshot } from "valtio";
import { useState, useEffect, useLayoutEffect } from "react";
import ImageGallery from "./MainUI/ImageGallery.jsx";
import DeviceToOld from "./MainUI/DeviceToOld.jsx";
import CloseButtonLowModelOrbit from "./MainUI/CloseButtonLowModelOrbit.jsx";
import CloseButtonLowCharacterSwitch from "./MainUI/CloseButtonCharacterSwitch.jsx";
import CloseButtonOrbit from "./MainUI/CloseButtonOrbit.jsx";
import Impressum from "./MainUI/Impressum.jsx";
import {
  stateWallPush,
  statePlayerSwitch,
  stateTouchCircleLook,
  stateIframeWindow,
  stateLoadingScreen,
  stateTouchCircle,
  stateOrbitLocation,
  statePlayerPositionS,
  stateInArea,
  stateMemory,
 stateEnglisch
} from "./store.jsx";
// import Schnellreise from "./MainUI/Schnellreise.jsx";
import TeleportingScreen from "./MainUI/TeleportingScreen.jsx";

export default function UiManager({ setGravity, setAniRunEndDone }) {
  const [start, setStart] = useState(false);
  const snapPlayerSwitch = useSnapshot(statePlayerSwitch);
  const snapTouchCircleLook = useSnapshot(stateTouchCircleLook);
  const snapTouchCircle = useSnapshot(stateTouchCircle);
  const snapOrbitLocation = useSnapshot(stateOrbitLocation);
  const snapPlayerPositionS = useSnapshot(statePlayerPositionS);
  const snapWallPushIndex = useSnapshot(stateWallPush);
  const snapLaodingscreen = useSnapshot(stateLoadingScreen);
  const snapIframeWindow = useSnapshot(stateIframeWindow);
  const snapStateMemory = useSnapshot(stateMemory);
  const snapStateEnglisch = useSnapshot(stateEnglisch)
const snapInArea = useSnapshot(stateInArea)


  const roomTitelArrayDe = [
    "Die Salzzeit Museen",
    "Die Holzarbeit",
    "Die Ökologie",
    "Das Salz",
    "Der Salztransport",
    "Die Kulturgeschichte",
  ];

  const roomTitelArrayEn = [
    "The Salzzeit Museums",
    "The woodwork",
    "The ecology",
    "The salt",
    "The transport of salt",
    "The cultural history",
  ];

  const roomTitelArray = snapStateEnglisch.isEnglisch ? roomTitelArrayEn : roomTitelArrayDe

  const [roomArray, setRoomArray] = useState([-1])
  const [shouldShowLoader, setShouldShowLoader] = useState(true)
  const [lastInfoWindowTimout, setLastInfoWindowTimout] =useState(false)


//   useEffect(()=>{
// if(shouldShowLoader)
// {
//   stateLoadingScreen.showLoaderRooms = true
// }else stateLoadingScreen.showLoaderRooms = false

//   },[shouldShowLoader])


useEffect(() =>{
if (start){
  stateLoadingScreen.startPressed = true
}

}, [start])

  useEffect(() => {
    stateInArea.inArea = false;
    // stateIframeWindow.renderCanvas = true;
  }, [snapLaodingscreen.loadingScreenAnimate]);

  useEffect(() => {
    stateInArea.inArea = false;
  
    let timerId; // Declare the timerId variable outside the if block
  
    if (snapLaodingscreen.lastInfoWindowClick) {
      const delay = 1000; // Set your desired delay time in milliseconds
      timerId = setTimeout(() => {
        setLastInfoWindowTimout(true);
      }, delay);
    }
  
    return () => {
      if (timerId) {
        clearTimeout(timerId); // Clear the timeout only if timerId is defined
      }
    };
  }, [snapLaodingscreen.lastInfoWindowClick]);

  useEffect(()=>{
    // setShouldShowLoader(!roomArray.includes(snapWallPushIndex.activeThemeIndex))
    // setRoomArray(oldArray => [...oldArray, snapWallPushIndex.activeThemeIndex])
    // Check if the newActiveThemeIndex is not already in roomArray
    // Check if snapWallPushIndex.activeThemeIndex is not already in roomArray
    if (!roomArray.includes(snapWallPushIndex.activeThemeIndex)) {
      setRoomArray(oldArray => [...oldArray, snapWallPushIndex.activeThemeIndex]);
    }
  },[snapWallPushIndex.activeThemeIndex])

  useLayoutEffect(()=>{

    setShouldShowLoader(!roomArray.includes(snapWallPushIndex.activeThemeIndex))

  })




  return (
    <>
    <TeleportingScreen></TeleportingScreen>
    {/* <AnimatePresence key={"Schnellreise"}>
    {lastInfoWindowTimout && <Schnellreise></Schnellreise>}
    </AnimatePresence> */}
<Impressum></Impressum>
<ImageGallery></ImageGallery>

      {lastInfoWindowTimout &&
      
        !snapPlayerSwitch.charSwitchClicked &&
        !snapOrbitLocation.orbitClicked && <JoystickMovement />}

      {lastInfoWindowTimout &&
        snapTouchCircleLook.JoystickLookVisible &&
        !snapTouchCircle.joystickMove &&
        !snapPlayerSwitch.charSwitchClicked &&
        !snapOrbitLocation.orbitClicked &&
        !snapPlayerPositionS.fpPlayer && <JoystickLook />}

      {snapIframeWindow.showIframe && (
        <IframeWindow
          src={snapIframeWindow.src} // Replace with your desired URL
          // onClose={stateIframeWindow.showIframe = false}
        />
      )}

      <AnimatePresence key={"Loading"} onExitComplete={() => setAniRunEndDone(true)}>
        {!start && (
          <LoadingBar
            started={start}
            onStarted={() => setStart(true) & setGravity(-1000)}
          />
        ) }
      </AnimatePresence>

      <AnimatePresence key={"Toolbar"}>
        {snapLaodingscreen.lastInfoWindowClick &&
          !snapPlayerSwitch.characterUiOn && snapInArea.inArea &&  <ToolBar/>}
      </AnimatePresence>
      <AnimatePresence key={"Header"}>
        {snapLaodingscreen.lastInfoWindowClick && <Header />}
        {snapPlayerSwitch.characterUiOn && (
          <CharacterSwitchUi></CharacterSwitchUi>
        )}
        {snapPlayerSwitch.characterUiOn && (  <CloseButtonLowCharacterSwitch></CloseButtonLowCharacterSwitch>  )}
      </AnimatePresence>
      <AnimatePresence key={"Infowindow"}>
        {start && <InfoWindowStory />}
      </AnimatePresence>

     <DeviceToOld showDeviceToOld={snapStateMemory.deviceToOld}></DeviceToOld>

      {start && <SoundBackground></SoundBackground>}
   {snapIframeWindow.LowModelOrbit && <CloseButtonLowModelOrbit></CloseButtonLowModelOrbit>}
    {snapOrbitLocation.orbitClicked && <CloseButtonOrbit></CloseButtonOrbit>}
      { snapWallPushIndex.activeThemeIndex != -1 && <CustomLoaderProgress
          title={roomTitelArray[snapWallPushIndex.activeThemeIndex]}
          text={"Content wird geladen"}
        />}
{   snapPlayerPositionS.homeclicked &&  <CustomLoaderProgress
          title={roomTitelArray[snapWallPushIndex.activeThemeIndex]}
          text={"Going back to Entry Room"}
        />}
    </>
  );
}
